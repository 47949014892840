import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Iframe from "react-iframe";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";
import { useSelector } from "react-redux";
import './CabinetCategories.css';
import PDFReader from "../PDFReader/PDFReader";


const CabinetCategories = ({ cabinet }) => {
  
  const cabinetCategories = useSelector(state => state.cabinetCategory.all);
  return (
    <>
      <h1 className="text-white text-start text-uppercase font_family p-0 mb-0 cabinet-title">{cabinet.Name}</h1>
      {cabinetCategories.map((cabinetCategory, ind) => (
        <CabinetCategory category={cabinetCategory} cabinet={cabinet} key={ind} />
      )
      )}
    </>
  );
};



export default CabinetCategories;

const CabinetCategory = ({ category, cabinet }) => {
  const [showModal, setShowModal] = useState(false);
  const [selecteCabinetItem, setCabinetItem] = useState(false);

  const [backgroundColor, setBackgroundColor] = useState("#18181b");
  const [pdfInfo, setPDFInfo] = useState({
    numPages: null,
    pageNumber: 1,
  });
  const cabinetItems = useSelector(state => state.cabinetItems.all);
  
  const handleMouseOver = (cabinetItem) => {
    setBackgroundColor(cabinetItem.picture.colors[0])
  }
  const handleMouseOut = () => {
    setBackgroundColor("#18181b")
  }

  const getCabinetItems = () => {
    let ret_abinetItems = [];
    for (let i = 0; i < cabinetItems.length; i++) {

      let existed = cabinet.CabinetItems.some(function (el) {
        return (el._id === cabinetItems[i]._id) && (cabinetItems[i].Category._id === category._id);
      });
      if (existed) ret_abinetItems.push(cabinetItems[i]);
    }
    return ret_abinetItems;
  }
  const handleClickCabinet = (_cabinetItem)=> {
    console.log(_cabinetItem);
    setCabinetItem(_cabinetItem);
    setShowModal(true);
  }

  const handlePdfLoad = (info) =>{
    setPDFInfo({
      numPages: info._pdfInfo.numPages,
      pageNumber: 1,
    })
  }
  if (getCabinetItems().length === 0) return (<></>);

  return (
    <div
      className="row flex home-videos align-items-center from-zinc-900 bg-gradient-to-t"
      key={category._id}
      style={{ backgroundColor: backgroundColor, borderRadius: "0.5rem", margin: "0.5rem", padding: "0.5rem", transitionDuration: "1s" }}
    >
      <h5 className="text-white text-start text-uppercase font_family p-0 mb-0">
        <i> {category.Label}</i>
      </h5>
      <Swiper
        style={{ "--swiper-pagination-bullet-inactive-opacity": "1" }}
        spaceBetween={15}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper"
        navigation={true}
        breakpoints={{
          399: {
            slidesPerView: 1,
          },
          // when window width is >= 530px
          400: {
            slidesPerView: 2,
          },
          // when window width is >= 768px
          640: {
            slidesPerView: 3,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 4,
          },
          // when window width is >= 1440px
          1280: {
            slidesPerView: 5,
          },
          // when window width is >= 1800px
          1536: {
            slidesPerView: 6,
          },
        }}
      >
        {
          getCabinetItems().map((cabinetItem) => (
            <SwiperSlide key={cabinetItem._id} onClick={()=> handleClickCabinet(cabinetItem)} onMouseOver={() => handleMouseOver(cabinetItem)} onMouseOut={() => handleMouseOut()}>
              <div className="d-flex align-items-center justify-center align-content-center p-3">
                <img
                  src={process.env.REACT_APP_IMAGE_PATH + cabinetItem.picture.path}
                  alt={cabinetItem.picture.title}
                  title={cabinetItem.Name}
                  className="hipHop_slide-image"
                />
              </div>
              <p className="px-3 pt-0 pb-3">{cabinetItem.Name}</p>

            </SwiperSlide>
          ))
        }
      </Swiper>
      {showModal && selecteCabinetItem ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            onClick={() => setShowModal(false)}
          >
            <div 
              className="relative w-auto my-3 mx-auto max-w-3xl"
              onClick={e => {e.stopPropagation();}}
            >
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none" style={{backgroundColor:'#37474f'}}>
                {/*header*/}
                <div className="flex items-start justify-between p-3 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold text-white">
                    {selecteCabinetItem.Name}
                  </h3>
                </div>
               
                <div className="relative p-3 flex-auto">
                  {
                    selecteCabinetItem.Type === "VIDEO"?(
                      <Iframe url={selecteCabinetItem.url} width="100%" height="400" frameborder="0" allow="autoplay; fullscreen" allowfullscreen/>
                    ): (selecteCabinetItem.Type === "PDF"?(
                        <PDFReader file={"/sample.pdf"}/>
                    ):"")
                  }
                </div>
                
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-3 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  )
}
